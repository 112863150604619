<template>
	<!-- 轮播图 -->
	<div class="box" v-if="!Details" style="padding: 70px 0;">
		<a-list :style="{ background: '#fff' }">
			<a-list-item v-for="(item, index) in NewsItems" :key="item.id" @click="Tackdetails(item)">
				<a-list-item-meta :style="{ padding: '20px' }">
					<template #description>
						<a-typography-paragraph
							:ellipsis="{
								rows: 3
							}"
						>
							{{ item.content.replace(/<[^>]*>/g, '') }}
						</a-typography-paragraph>
					</template>

					<template #avatar>
						<a-avatar shape="square" :style="{ width: '148px', height: '100px' }"><img alt="avatar" :src="item.image" /></a-avatar>
					</template>
					<template #title>
						<div class="flex ">
							<div>{{ item.title }}</div>
							<div style="margin-left: 20px;">发布时间：{{ item.create_at }}</div>
						</div>
					</template>
				</a-list-item-meta>
			</a-list-item>
		</a-list>
	</div>
	<div class="bg-white">
		<div v-if="Details">
			<img alt="avatar" :style="{ width: '100%', height: '416px' }" src="@/assets/svg/sucai6.png" />

			<div class="box">
				<div style="padding: 20px 0 ;">
					<a-breadcrumb :style="{ color: '#fff' }">
						<a-breadcrumb-item @click.stop="Details = false">
							<icon-home />
							列表
						</a-breadcrumb-item>
						<a-breadcrumb-item>当前正在查看：{{ newDeail.title }}</a-breadcrumb-item>
					</a-breadcrumb>
				</div>
				<div class="text-center title">{{ newDeail.title }}</div>
				<div class="text-center" style="padding: 20px 0 ;">发布时间：{{ newDeail.create_at }}</div>
				<a-divider dashed />
				<div class="item-box w-e-text" v-html="newDeail.content"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, toRef } from 'vue';
import { useRouter } from 'vue-router';
import api from '@/api';
const props = defineProps({
	newDeail: {
		type: Object,
		default: null
	},
	Details: {
		type: Boolean,
		default: false
	}
});

const NewsItems = ref([]);
const GetNews = () => {
	api.news.news
		.list({
			text: '',
			page: 1,
			row: 999
		})
		.then(res => {
			let arr = res.data.data;
			NewsItems.value = arr;
		});
};
const Details = ref(props.Details);
const newDeail = ref(props.newDeail);
const Tackdetails = data => {
	newDeail.value = data;
	Details.value = true;
};

onMounted(() => {
	GetNews();
});
</script>

<style lang="scss">
// 服务信息
.service_color {
	background-color: #fff;
	color: #000000;
}
.service_active {
	background-color: #7d9b43;
	color: #fff;
}
.title {
	font-size: 30px;
	font-weight: bold;
}

.box {
	width: calc(100% - 200px);
	margin: 0 auto;
}
</style>
