<template>
	<div class="layout-demo">
		<a-layout>
			<a-affix :offsetTop="0">
				<a-layout-header><Header @toSonEvent="changeTitle" ref="header" /></a-layout-header>
			</a-affix>
			<a-layout-content>
				<ContentHome v-if="navType == 1" :header="header" ref="home" :home="home" />
				<ContentServe v-if="navType == 2" />
				<ContentContact v-if="navType == 3" :header="header" ref="contact" :contact="contact" />
				<contentNew v-if="navType == 4" :Details="Details" :newDeail="newDeail" />
				<ContentOrder v-if="navType == 5" />
			</a-layout-content>
			<a-layout-footer><Footer /></a-layout-footer>
		</a-layout>
	</div>
</template>
<script setup>
import Header from './header/header.vue';
import ContentHome from './content/content-home.vue';
import ContentServe from './content/content-serve.vue';
import ContentContact from './content/content-contact.vue';
import ContentOrder from './content/content-order.vue';
import ContentNew from './content/content-new.vue';
import Footer from './footer/footer.vue';
import { reactive, provide, ref } from 'vue';
import  api from '@/api'
const navType = ref(1);
const Details = ref(false);
const newDeail = ref(null);
const header = ref(null);
const home = ref(null);
const about = ref(null);
const contact = ref(null);
const changeTitle = fromSonParams => {

	//接收子组件传递过来的参数
	navType.value = fromSonParams;
	if (home.value != null) {
		Details.value = home.value.Details;
		newDeail.value = home.value.newDeail;
	}

	if (about.value != null) {
		Details.value = about.value.Details;
		newDeail.value = about.value.newDeail;
	}
	if (contact.value != null) {
		Details.value = contact.value.Details;
		newDeail.value = contact.value.newDeail;

	}
};




</script>
<style scoped lang="scss"></style>
