const req = require.context("./", true, /\.js$/);

const api = {};

/**
 * 接口主文件
 * 接口文件请按文件区分，使用 api.文件名.方法如 api.base.login()
 */

req.keys().forEach((item) => {
    if (item !== "./index.js") {
        let keys = item.split("/").pop().replace(/\.js$/, "");
        let moduleFile = req(item);
        let value = moduleFile.default || moduleFile;
        api[keys] = value;
    }
});

export default api;