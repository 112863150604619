<template>
	<!-- 轮播图 -->
	<div>
		<a-carousel
			:auto-play="true"
			indicator-type="dot"
			show-arrow="hover"
			:style="{
				height: '520px'
			}"
		>
			<a-carousel-item v-for="item in bannerList" :key="item.id">
				<img
					:src="item.url"
					:style="{
						width: '100%',
						height: '100%'
					}"
				/>
			</a-carousel-item>
		</a-carousel>
	</div>

	<!-- 服务项目 -->
	<div style="background-color: #FAFAFA;">
		<div class="box">
			<div style="padding-top: 33px;" class="text-center">
				<div class="title">{{ $t('message.ServiceItems') }}</div>
				<div style="margin-top: 6px;">Service Items</div>
			</div>
			<div style="margin-top: 46px;">
				<a-row :gutter="20">
					<a-col :span="8" v-for="(item, index) in projectList" :key="item.id" @mouseover="serviceType" :data-id="item.id">
						<div :style="{ marginTop: '20px' }">
							<img alt="avatar" :style="{ width: '100%', height: '342px' }" :src="item.url" />
							<div style="line-height: 66px;height: 66px;" class="text-center" :class="item.id == serviceId ? 'service_active ' : 'service_color'">
								<span v-if="ctx.$i18n.locale === 'zh-CN'">{{ item.title_cn }}</span>
								<span v-if="ctx.$i18n.locale === 'en-US'">{{ item.title_en }}</span>
							</div>
						</div>
					</a-col>
				</a-row>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import api from '@/api';
const props = defineProps(['navType']);
const { ctx } = getCurrentInstance();
const serviceId = ref(1);
const bannerList = ref([]);
const Getbaner = () => {
	api.news
		.getList({
			type: 2,
			page: 1,
			row: 999
		})
		.then(res => {
			bannerList.value = res.data;
		});
};
const projectList = ref([]);
const Getproject = () => {
	api.news
		.projectList({
			page: 1,
			row: 999
		})
		.then(res => {
			projectList.value = res.data;
		});
};

const serviceType = val => {
	serviceId.value = val.currentTarget.dataset.id;
};
onMounted(() => {
	Getbaner();
	Getproject();
});
</script>

<style lang="scss">
// 服务信息
.service_color {
	background-color: #fff;
	color: #000000;
}
.service_active {
	background-color: #f18700;
	color: #fff;
}
.title {
	font-size: 30px;
	font-weight: bold;
}

.box {
	width: calc(100% - 200px);
	margin: 0 auto;
	padding-bottom: 70px;
}
</style>
