import {
	createApp
} from 'vue'

//默认样式
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import ArcoVue from '@arco-design/web-vue';
import i18n from "@/language";
// 额外引入图标库
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';



//rem适配文件
const app = createApp(App);

app.use(store);
app.use(router)
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(i18n);
app.mount('#app')
