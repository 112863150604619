<script setup>
import { getCurrentInstance } from 'vue';
import { RouterView } from 'vue-router';
import DevicePixelRatio from './utlis/devicePixelRatio';
const { ctx } = getCurrentInstance();
new DevicePixelRatio().init();

// 自适应
</script>
<template>
	<RouterView />
</template>
<style lang="scss">
@import url('./style/color.css');
@import url('./style/Mormalize.css');
@import url('./style/home.css');

html,
body,
#app {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}
</style>
