<template>
	<div class="bg-white">
		<div style="height: 360px;background-color: #353436;">
			<div style="margin: 0 auto;width:calc(100% - 200px);">
				<a-list :gridProps="{ gutter: 0, span: 8 }" :bordered="false" :split="false" v-if="contactList.length != 0">
					<a-list-item>
						<a-list :bordered="false" :split="false" size="medium">
							<a-list-item class="text-gray" style="font-size: 13px">{{ $t('message.Nigeria') }}:</a-list-item>
							<a-list-item class="text-gray" style="font-size: 13px">
								<div>
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].contacts_name }}</span>
									</span>
								</div>

								<div>
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].contact }}</span>
									</span>
								</div>

								<div>
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].email }}</span>
									</span>
								</div>

								<div>
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].address }}</span>
									</span>
								</div>
							</a-list-item>
						</a-list>
						<a-list :bordered="false" :split="false" size="medium">
							<a-list-item class="text-gray" style="font-size: 13px">{{ $t('message.Nigeria') }}:</a-list-item>

							<a-list-item class="text-gray" style="font-size: 13px">
								<div>
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].contacts_name }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].contact }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].email }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].address }}</span>
									</span>
								</div>
							</a-list-item>
						</a-list>
					</a-list-item>
					<a-list-item>
						<a-list :bordered="false" :split="false">
							<a-list-item class="text-gray" style="font-size: 13px">{{ $t('message.China') }}:</a-list-item>

							<a-list-item class="text-gray " style="font-size: 13px">
								<div>
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].contacts_name }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].contact }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].email }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].address }}</span>
									</span>
								</div>
							</a-list-item>
						</a-list>
						<a-list :bordered="false" :split="false">
							<a-list-item class="text-gray" style="font-size: 13px">{{ $t('message.Indonesia') }}:</a-list-item>

							<a-list-item class="text-gray " style="font-size: 13px">
								<div>
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].contacts_name }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].contact }}</span>
									</span>
								</div>
								<div>
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].email }}</span>
									</span>
								</div>

								<div>
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].address }}</span>
									</span>
								</div>
							</a-list-item>
						</a-list>
					</a-list-item>
					<a-list-item>
						<a-list :bordered="false" :split="false">
							<a-list-item class="text-gray text-right" style="font-size: 13px">2021 {{ $t('message.Copyright') }}</a-list-item>
						</a-list>
					</a-list-item>
				</a-list>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import api from '@/api';
const { ctx } = getCurrentInstance();
const contactList = ref([]);

const Getcontact = () => {
	api.news.syslist().then(res => {
		contactList.value = res.data;
	});
};
onMounted(() => {
	Getcontact();
});
</script>

<style></style>
