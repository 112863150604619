<template>
	<!-- 轮播图 -->
	<div class="bg-white">
		<div>
			<a-carousel
				:auto-play="true"
				indicator-type="dot"
				show-arrow="hover"
				:style="{
					height: '520px'
				}"
			>
				<a-carousel-item v-for="item in bannerList" :key="item.id">
					<img
						:src="item.url"
						:style="{
							width: '100%',
							height: '100%'
						}"
					/>
				</a-carousel-item>
			</a-carousel>
		</div>

		<div style="padding-top: 100px">
			<div class="box">
				<a-row>
					<a-col :span="16">
						<div style="font-size: 30px" class="text-black text-bold">{{ $t('message.CompanyName') }}</div>
						<div style="font-weight: 400; color: #7d9b43; padding-top: 18px">为您提供一站式综合物流运输服务</div>
						<div style="font-weight: 400; color: #7d9b43; padding-top: 10px">To provide you with one-stop integrated logistics transport services</div>
						<a-divider />
						<div>
							<span style="font-weight: bold;font-size: 20px">{{ $t('message.Nigeria') }}</span>
							<a-row :gutter="20" v-if="contactList.length != 0" style="margin-top: 20px">
								<a-col :span="4">
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].contacts_name }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].contact }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].email }}</span>
									</span>
								</a-col>

								<a-col :span="8">
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[0].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[1].address }}</span>
									</span>
								</a-col>
							</a-row>
						</div>

						<div style="margin-top: 20px">
							<span style="font-weight: bold;font-size: 20px">{{ $t('message.Nigeria') }}</span>
							<a-row :gutter="20" v-if="contactList.length != 0" style="margin-top: 20px">
								<a-col :span="4">
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].contacts_name }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].contact }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].email }}</span>
									</span>
								</a-col>
								<a-col :span="8">
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[6].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[7].address }}</span>
									</span>
								</a-col>
							</a-row>
						</div>
						<div style="margin-top: 20px">
							<span style="font-weight: bold;font-size: 20px">{{ $t('message.China') }}</span>
							<a-row :gutter="20" v-if="contactList.length != 0" style="margin-top: 20px">
								<a-col :span="4">
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].contacts_name }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].contact }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].email }}</span>
									</span>
								</a-col>

								<a-col :span="8">
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[2].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[3].address }}</span>
									</span>
								</a-col>
							</a-row>
						</div>
						<div style="margin-top: 20px">
							<span style="font-weight: bold;font-size: 20px">{{ $t('message.Indonesia') }}</span>
							<a-row :gutter="20" v-if="contactList.length != 0" style="margin-top: 20px">
								<a-col :span="4">
									<span>
										{{ $t('message.contacts') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].contacts_name }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].contacts_name }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.contactInformation') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].contact }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].contact }}</span>
									</span>
								</a-col>
								<a-col :span="6">
									<span>
										{{ $t('message.EMail') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].email }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].email }}</span>
									</span>
								</a-col>
								<a-col :span="8">
									<span>
										{{ $t('message.warehouseAddress') }}：
										<span v-if="ctx.$i18n.locale == 'zh-CN'">{{ contactList[4].address }}</span>
										<span v-if="ctx.$i18n.locale !== 'zh-CN'">{{ contactList[5].address }}</span>
									</span>
								</a-col>
							</a-row>
						</div>
					</a-col>
					<a-col :span="8">
						<a-form :model="formData" :style="{ width: '600px' }">
							<a-form-item><a-input v-model="formData.full_name" :placeholder="ctx.$i18n.locale !== 'zh-CN' ? 'Please enter your name' : '请输入您的姓名'" /></a-form-item>
							<a-form-item>
								<a-input v-model="formData.telephone" :placeholder="ctx.$i18n.locale !== 'zh-CN' ? 'Please enter your contact number' : '请输入您的联系电话'" />
							</a-form-item>
							<a-form-item>
								<a-input v-model="formData.email" :placeholder="ctx.$i18n.locale !== 'zh-CN' ? 'Please enter your contact email address' : '请输入您的联系邮箱'" />
							</a-form-item>
							<a-form-item>
								<a-mention
									v-model="formData.remark"
									type="textarea"
									:placeholder="ctx.$i18n.locale !== 'zh-CN' ? 'Please enter comments' : '请输入备注'"
									:style="{ height: '155px' }"
								/>
							</a-form-item>

							<a-form-item>
								<a-button
									@click.stop="Submit"
									html-type="submit"
									:style="{
										background: '#7d9b43',
										color: '#fff',
										width: '182px',
										height: '64px'
									}"
								>
									{{ ctx.$i18n.locale !== 'zh-CN' ? 'Submit' : '提交' }}
								</a-button>
							</a-form-item>
						</a-form>
					</a-col>
				</a-row>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, getCurrentInstance, reactive, onMounted, defineExpose } from 'vue';
import api from '@/api';
const props = defineProps(['header', 'contact']);
const { ctx } = getCurrentInstance();
const contactList = ref([]);
const Getcontact = () => {
	api.news.syslist().then(res => {
		contactList.value = res.data;
	});
};
const bannerList = ref([]);
const Getbaner = () => {
	api.news
		.getList({
			type: 3,
			page: 1,
			row: 999
		})
		.then(res => {
			bannerList.value = res.data;
		});
};
const formData = reactive({
	full_name: '',
	telephone: '',
	email: '',
	remark: ''
});

const Submit = () => {
	api.order.MessageAdd(formData).then(res => {
		if (res.code === 0) {
			Object.keys(this.formData).forEach(key => {
				this.formData[key] = '';
			});
		}
	});
};
const firstNew = ref(null);
const NewsItems = ref([]);
const GetNews = () => {
	api.news.news
		.list({
			text: '',
			page: 1,
			row: 5
		})
		.then(res => {
			let arr = res.data.data;
			firstNew.value = arr[0];
			NewsItems.value = arr.slice(1);
		});
};
const Details = ref(false);
const newDeail = ref(null);
const Tackdetails = data => {
	newDeail.value = data;
	Details.value = true;
	props.header.headerSelect(5);
};
const images = ref([
	{
		url: require('@/assets/svg/sucai16.png')
	},
	{
		url: require('@/assets/svg/sucai16.png')
	}
]);

const serviceType = val => {
	serviceId.value = val.currentTarget.dataset.id;
};
onMounted(() => {
	GetNews();
	Getbaner();
	Getcontact();
});
defineExpose({ Details, newDeail, Getcontact });
</script>

<style lang="scss">
// 服务信息
.service_color {
	background-color: #fff;
	color: #000000;
}
.service_active {
	background-color: #f18700;
	color: #fff;
}
.title {
	font-size: 30px;
	font-weight: bold;
}

.box {
	width: calc(100% - 200px);
	margin: 0 auto;
	padding-bottom: 70px;
}
</style>
