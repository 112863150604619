export default {
	message: {
		Chinese: '中文',
		home: '首页',
		AboutUs: '关于我们',
		OurServices: '我们的服务',
		ContactUs: '联络我们',
		CargoTracking: '货物追踪',
		NigeriaT: '尼日利亚联系方式',
		Domestic: '国内联系方式',
		Address: '地址',
		Phone: '电话',
		EMail: '联系邮箱',
		Copyright: '德莱米克供应链 版权所有',
		MobilePhoneVersion: '手机版',
		NewsCenter: '新闻中心',
		ServiceItems: '服务项目',
		PopularTransportation: '热门运输',
		SeaTransportation: '海运',
		RoadTransportation: '公路运输',
		NigeriaClearance: '尼日利亚进口清关',
		ShippingLCL: '海运与拼箱',
		WestAfricantransport: '西非陆路运输',
		Nigerianexport: '尼日利亚出口',
		warehouseService: '仓储服务',
		Localdistribution: '当地配送',
		CompanyProfile: '公司简介',
		CompanyName: '德莱米克供应链有限公司',
		CompanyTitle: "德莱米克供应链有限公司自创建以来，以立足于尼日利亚本地，专注于中国与尼日利亚间的海空运物流，我们的优势在于尼日利亚海运进口清关，出口快速进港，海运散货拼箱，以及中国到尼日利亚空运清关和本地仓储和配送。",
		workConcept: '工作理念',
		worktitle: '用心服务诠释我的真情，真诚奉献赢取您的满意',
		enterpriseCulture: '企业文化',
		enterpriseTitle: '成就客户，就是成就我们自己',
		serviceConcept: '服务理念',
		serviceTitle: '专注中尼物流， 降低中尼间物流成本， 提高物流效率 企业使命 为员工提供平台， 为客户成就尼日 利亚市场， 为客户降低中尼间的物流成本、 提高物流效率从而更好的参与市场竟争， 我们希望在此 过程中能成为您最好的合作伙伴及助手。 ',
		content1: '德来米克在拉各斯拥有尼联邦批准成产保税仓库，进口货物包括集装箱，散货都转运到保税仓清关。快速高效完成清关过程。并且德莱米克尼日利亚公司自运营40辆集卡拖车，能在港口拥挤时，不受高运价的影响。',
		content2: '德来米克提供专业的海运服务和拼箱解决方案，最优质的一站式服务。无论您货物在中国哪里，无论货物的数量是多少，我们的专业团队都能从您的需求出发，为您度身定制低成本、高效率的最佳海运或者拼箱方案。',
		content3: '德来米克拥有成熟的西非洲各国间陆路运输经验，从尼日利来跨境到尼日尔，贝宁，多哥，加纳，布基纳法索，喀麦隆等各国。',
		content4: '德来米克在拉各斯租用船公司空箱堆场，客户的出口货物可直接装入空箱内，减少调拨空箱的痛苦。 在哈科特港拥有5000平方的堆场，客户可以任何时候把任何数量的出口货物运达此堆场。',
		content5: '德来米克在拉各斯，卡诺，奥尼查，阿布贾都拥有仓库 ，运营仓库的总面积超过3000平米，业务覆盖海空运进口，区域配送，满足您不同的仓储需求。仓库人员二十四小时轮值，保障每票货物都顺利储运，让客户放心、展现我们的专业是我们致力的目标。',
		content6: '德来米克拥有完善的当地配送网络，解决本地配送的难题是我们一直努力的方向。实现本地门到门服务，为电商解决“最后一公里”的困难。',
		Cargotracking: '货物追踪中心',
		Search: '搜索',
		trackingNumber: '转单号',
		details: '查看详情',

		New: '新闻中心',
		warehousing: '入仓',
		Containerloading: '装柜',
		Setsail: '开船',
		transshipment: '转运',
		Arrivalatport: '到港',
		Customsclearance: '清关',
		dispatch: '派送',
		Loadedcontainer: '已装柜',
		Indonesia: '印尼',
		China: '中国',
		Nigeria: '尼日利亚',
		contactInformation: '联系方式',
		contacts: '联系人',
		warehouseAddress: '仓库地址',
		EMail: '邮箱',
		LogoTitle: '德莱米克供应链'
	},
}