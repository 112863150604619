<template>
	<!-- 轮播图 -->
	<div class="bg-white">
		<a-carousel
			:auto-play="true"
			indicator-type="dot"
			show-arrow="hover"
			:style="{
				height: '520px'
			}"
		>
			<a-carousel-item v-for="item in bannerList" :key="item.id">
				<img
					:src="item.url"
					:style="{
						width: '100%',
						height: '100%'
					}"
				/>
			</a-carousel-item>
		</a-carousel>

		<!-- 新闻板块 -->

		<div class="new_box">
			<div style="margin-top: 43px;" class="text-center">
				<div class="title">{{ $t('message.NewsCenter') }}</div>
				<div style="margin-top: 6px;">News Center</div>
			</div>
			<div class="flex align-center" style="margin-top: 46px;">
				<div style="width: 50%;">
					<a-card hoverable @click="Tackdetails(firstNew)">
						<template #cover>
							<div :style="{ height: '362px' }"><img v-if="firstNew != null" :style="{ width: '100%', height: '100%' }" alt="" :src="firstNew.image" /></div>
						</template>
						<a-card-meta>
							<template #description>
								<a-typography-paragraph
									:ellipsis="{
										rows: 2
									}"
									v-if="firstNew != null"
								>
									{{ firstNew.content.replace(/<[^>]*>/g, '') }}
								</a-typography-paragraph>
							</template>
						</a-card-meta>
					</a-card>
				</div>
				<div style="width: 50%;">
					<a-list :bordered="false" :split="false">
						<a-list-item v-for="(item, index) in NewsItems" :key="item.id" :style="{ padding: '5px 20px', lineHeight: 'normal' }" @click="Tackdetails(item)">
							<a-list-item-meta :title="item.title" :style="{ padding: '0px' }">
								<template #description>
									<a-typography-paragraph
										:ellipsis="{
											rows: 3
										}"
									>
										{{ item.content.replace(/<[^>]*>/g, '') }}
									</a-typography-paragraph>
								</template>
								<template #avatar>
									<a-avatar shape="square" :style="{ width: '148px', height: '100px' }"><img alt="avatar" :src="item.image" /></a-avatar>
								</template>
							</a-list-item-meta>
						</a-list-item>
					</a-list>
				</div>
			</div>
		</div>
		<!-- 服务项目 -->
		<div style="background-color: #FAFAFA;">
			<div class="box">
				<div style="padding-top: 33px;" class="text-center">
					<div class="title">{{ $t('message.ServiceItems') }}</div>
					<div style="margin-top: 6px;">Service Items</div>
				</div>
				<div style="margin-top: 46px;">
					<a-row :gutter="20">
						<a-col :span="8" v-for="(item, index) in projectList" :key="item.id" @mouseover="serviceType" :data-id="item.id">
							<div :style="{ marginTop: '20px' }">
								<img alt="avatar" :style="{ width: '100%', height: '342px' }" :src="item.url" />
								<div style="line-height: 66px;height: 66px;" class="text-center" :class="item.id == serviceId ? 'service_active ' : 'service_color'">
									<span v-if="ctx.$i18n.locale === 'zh-CN'">{{ item.title_cn }}</span>
									<span v-if="ctx.$i18n.locale === 'en-US'">{{ item.title_en }}</span>
								</div>
							</div>
						</a-col>
					</a-row>
				</div>
			</div>
		</div>
		<div><img alt="avatar" :style="{ width: '100%', height: '507px' }" src="@/assets/svg/sucai17.png" /></div>
		<div class="box">
			<div style="margin-top: 72px;" class="text-center">
				<div class="title">{{ $t('message.PopularTransportation') }}</div>
				<div style="margin-top: 6px;">Popular transportation</div>
			</div>
			<div style="margin-top: 42px;">
				<a-row :gutter="10">
					<a-col :span="12">
						<div class="" :style="{ width: '100%', height: '388px', position: 'relative' }" style="border-radius: 5px">
							<img alt="avatar" style="border-radius: 5px" :style="{ width: '100%', height: '100%' }" src="@/assets/svg/sucai5.png" />
							<div class="text-center text-bold box-title">
								<span style="">{{ $t('message.SeaTransportation') }}</span>
							</div>
						</div>
					</a-col>
					<a-col :span="12">
						<div class="" style="border-radius: 5px" :style="{ width: '100%', height: '388px', position: 'relative' }">
							<img alt="avatar" style="border-radius: 5px" :style="{ width: '100%', height: '100%' }" src="@/assets/svg/sucai6.png" />
							<div class="text-center text-bold box-title">
								<span style="">{{ $t('message.RoadTransportation') }}</span>
							</div>
						</div>
					</a-col>
				</a-row>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, getCurrentInstance } from 'vue';
import api from '@/api';
const { ctx } = getCurrentInstance();
const props = defineProps(['header', 'home']);

const firstNew = ref(null);
const NewsItems = ref([]);
const GetNews = () => {
	api.news.news
		.list({
			text: '',
			page: 1,
			row: 5
		})
		.then(res => {
			let arr = res.data.data;
			firstNew.value = arr[0];
			NewsItems.value = arr.slice(1);
		});
};
const bannerList = ref([]);
const Getbaner = () => {
	api.news
		.getList({
			type: 1,
			page: 1,
			row: 999
		})
		.then(res => {
			bannerList.value = res.data;
		});
};
const projectList = ref([]);
const Getproject = () => {
	api.news
		.projectList({
			type: 1,
			page: 1,
			row: 999
		})
		.then(res => {
			projectList.value = res.data;
		});
};
const Details = ref(false);
const newDeail = ref(null);

const Tackdetails = data => {
	newDeail.value = data;
	Details.value = true;
	props.header.headerSelect(4);
};

const serviceId = ref(1);

const serviceType = val => {
	serviceId.value = val.currentTarget.dataset.id;
};
defineExpose({ Details, newDeail });

onMounted(() => {
	GetNews();
	Getbaner();
	Getproject();
});
</script>

<style lang="scss">
// 服务信息
.service_color {
	background-color: #fff;
	color: #000000;
}
.service_active {
	background-color: #7d9b43;
	color: #fff;
}
.title {
	font-size: 30px;
	font-weight: bold;
}

.new_box {
	width: calc(100% - 200px);
	margin: 0 auto;
	padding-bottom: 40px;
}

.box {
	width: calc(100% - 200px);
	margin: 0 auto;
	padding-bottom: 70px;
}

.box-title {
	border-radius: 0 0 5px 5px;
	width: 100%;
	font-size: 18px;
	color: #ffffff;
	line-height: 63px;
	height: 63px;
	background: #000000;
	opacity: 0.29;
	position: absolute;
	bottom: 0;
}
</style>
