<template>
	<div class="bg-white">
		<!-- 轮播图 -->
		<div>
			<a-carousel
				:auto-play="true"
				indicator-type="dot"
				show-arrow="hover"
				:style="{
					height: '520px'
				}"
			>
				<a-carousel-item v-for="item in bannerList" :key="item.id">
					<img
						:src="item.url"
						:style="{
							width: '100%',
							height: '100%'
						}"
					/>
				</a-carousel-item>
			</a-carousel>
		</div>
		<div style="height: 216px;background-color: #353436;padding-top: 40px; transform: translateY(-5px)">
			<div class="text-center">
				<div style="font-size: 30px;" class="text-bold text-white">{{ $t('message.Cargotracking') }}</div>
				<div style="margin-top: 38px;">
					<a-input
						:style="{ width: '540px', height: '38px' }"
						:placeholder="ctx.$i18n.locale !== 'zh-CN' ? 'Please enter the tracking number' : '请输入单号'"
						allow-clear
						v-model="keywords"
						@keyup.enter.native="Search()"
					></a-input>
					<a-button :style="{ width: '65px', height: '38px', background: '#7d9b43' }" type="primary" @click.stop="Search">{{ $t('message.Search') }}</a-button>
				</div>
			</div>
		</div>
		<div :style="{ background: '#FAFAFA' }">
			<div class="box" v-if="orderData != null">
				<a-card>
					<div>
						<span class="text-bold" style="margin-right: 20px;" v-if="orderData != null">{{ orderData.order_no }}</span>

						<span style="margin-right: 20px;color: #7d9b43;">{{ $t('message.details') }}</span>
					</div>
					<div class="flex align-center justify-between">
						<div style="width: 800px;">
							<div class="flex align-center  text-bold" style="height: 28px;line-height: 28px;font-size: 20px;margin-top: 32px;">
								<div style="margin-right: 28px;">{{ $t('message.China') }}</div>
								<div><img alt="avatar" :style="{ width: '50px', height: '30px' }" src="@/assets/svg/aircraft.png" /></div>
								<div style="margin-left: 28px;" v-if="orderData">
                  {{ ctx.$i18n.locale !== 'zh-CN' ? orderData['country']['name_en'] : orderData['country']['name'] }}
                </div>
							</div>
							<div style="font-size: 14px;margin-top: 18px;color: #666666;">
								<div class="flex align-center justify-between">
<!--									<span v-if="orderData != null">-->
<!--										{{ ctx.$i18n.locale !== 'zh-CN' ? 'Commodity' : '品名' }}：-->
<!--										<span>{{ ctx.$i18n.locale == 'zh-CN' ? orderData.cargo_name : orderData.cargo_name_en }}</span>-->
<!--									</span>-->
									<span style="margin-left: 20px;" v-if="orderData != null">
										{{ ctx.$i18n.locale !== 'zh-CN' ? 'Weight' : '重量' }}：
										<span>{{ orderData.sum_weight }}kg</span>
									</span>
									<span style="margin-left: 20px;" v-if="orderData != null">
										{{ ctx.$i18n.locale !== 'zh-CN' ? 'Volume' : '体积' }}：
										<span>{{ orderData.price_volume }}m³</span>
									</span>
									<span style="margin-left: 20px;" v-if="orderData != null">
										{{ ctx.$i18n.locale !== 'zh-CN' ? 'Number' : '件数' }}：
										<span>{{ orderData.sum_number }}</span>
									</span>
								</div>
							</div>
						</div>
						<div class="flex align-center ">
							<div style="margin-left: 48px;"><img alt="avatar" :style="{ width: '48px', height: '48px' }" src="@/assets/svg/sucai27.png" /></div>
						</div>
					</div>
					<a-divider />
					<div style="padding: 20px;">
						<a-timeline>
							<a-timeline-item lineType="dashed" v-for="(item, index) in track_list" :key="index">
                {{ctx.$i18n.locale == 'zh-CN' ? item.message : item.en_message }}
							</a-timeline-item>
						</a-timeline>
					</div>
				</a-card>
			</div>
			<div class="box" v-if="orderData == null" style="height: 897px;padding-top: 80px;"><a-empty /></div>
		</div>
	</div>
</template>

<script setup>
import { ref, getCurrentInstance, reactive, onMounted } from 'vue';
import api from '@/api';
const { ctx } = getCurrentInstance();
const props = defineProps(['navType']);
const keywords = ref('');
const itemsId = ref(1);

const orderData = ref(null);
const track_list = ref([]);
const Search = () => {
	api.order.search({ order_no: keywords.value }).then(res => {
		if (res.code === 0) {
			orderData.value = res.data;
			track_list.value = res.data.tracking;
		}
	});
};
const bannerList = ref([]);
const Getbaner = () => {
	api.news
		.getList({
			type: 4,
			page: 1,
			row: 999
		})
		.then(res => {
			bannerList.value = res.data;
		});
};
onMounted(() => {
	Getbaner();
});
</script>

<style lang="scss">
// 服务信息
.item_color {
	color: #000000;
}
.item_active {
	color: #fff;
}
.title {
	font-size: 30px;
	font-weight: bold;
}

.box {
	width: calc(100% - 200px);
	margin: 0 auto;
	padding-bottom: 70px;
}
</style>
